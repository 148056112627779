window.Notification = {
  send: function(options = {}) {
    options["type"] = options["type"] || "alert";
    options["theme"] = "nest";
    options["killer"] = true;
    options["layout"] = options["layout"] || "topCenter";
    options["timeout"] = options["timeout"] || 3500;
    options["animation"] = {
      open: "animated noty_effects_open",
      close: "animated noty_effects_close"
    };

    new Noty(options).show();
  }
};
