// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var mobileScreenSize = 1080;

// checks if mobile size of window

window.isMobileSize = function() {
  return $(document).width() <= mobileScreenSize;
};

window.lazySizesConfig = window.lazySizesConfig || {};
lazySizesConfig.expFactor = 4;

navigator.vibrate =
  navigator.vibrate ||
  navigator.webkitVibrate ||
  navigator.mozVibrate ||
  navigator.msVibrate;

window.vibrate = function(timeInMs) {
  if (navigator.vibrate) {
    navigator.vibrate(timeInMs);
  }
};

window.resetIframe = function(iframeId, modalId, src) {
  if ($("#" + iframeId).length > 0) {
    $("#" + iframeId).prop("src", src);
  } else {
    $("#" + modalId)
      .find(".modal-body")
      .html(
        '<iframe id="' +
          iframeId +
          '" frameborder="0" src="' +
          src +
          '"></iframe>'
      );

    var interval = setInterval(function() {
      if ($("#" + iframeId).length > 0) {
        iFrameResize(
          {
            heightCalculationMethod: "taggedElement",
            resizeFrom: "child",
            onMessage: function(iframe) {
              if (iframe.message == "close") {
                $("#" + modalId).modal("hide");
              } else if (iframe.message == "reloadparent") {
                location.reload();
              } else {
                eval(iframe.message);
              }
            }
          },
          "#" + iframeId
        );
        clearInterval(interval);
      }
    }, 500);
  }
};

window.shaveText = function(selector, options) {
  var $selector = $(selector);
  var shaveTextHelper = function() {
    var height = isMobileSize() ? options["mobile"] : options["desktop"];
    if ($selector.height() > height && options["clickToOpen"] != false) {
      $selector.css("cursor", "pointer");
    }

    if ($selector.data("expanded")) {
      shave(selector, 999);
    } else {
      shave(selector, height);
    }
  };

  if (options["clickToOpen"] != false) {
    $selector.on("click", function() {
      if ($selector.data("expanded")) {
        $selector.removeData("expanded");
      } else {
        $selector.data("expanded", true);
      }
      shaveTextHelper();
    });
  }

  var resizeTimer;
  $(window).resize(function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(shaveTextHelper, 250);
  });
  shaveTextHelper();
};

window.toggleLineClamp = function(e) {
  if (e.target) {
    var target = $(e.target);
  } else {
    var target = $(e);
  }
  if (!$(e).hasClass("editable-content")) {
    $(e).toggleClass("active");
  }

  var btn = target.parent().find(".crunch-button__toggle-clamp");
  if (btn.length > 0) {
    if (btn.hasClass("active")) {
      btn.removeClass("active");
    } else {
      btn.addClass("active");
    }
  }
};

window.updateUrlParameter = function(uri, key, value) {
  // remove the hash part before operating on the uri
  var i = uri.indexOf("#");
  var hash = i === -1 ? "" : uri.substr(i);
  uri = i === -1 ? uri : uri.substr(0, i);

  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";

  if (!value) {
    // remove key-value pair if value is empty
    uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), "");
    if (uri.slice(-1) === "?") {
      uri = uri.slice(0, -1);
    }
    // replace first occurrence of & by ? if no ? is present
    if (uri.indexOf("?") === -1) uri = uri.replace(/&/, "?");
  } else if (uri.match(re)) {
    uri = uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    uri = uri + separator + key + "=" + value;
  }
  return uri + hash;
};

window.goToUrlParameter = function(key, val) {
  if (val != "" && val != null && val != undefined) {
    val = encodeURIComponent(val);
  }

  this.location.href = updateUrlParameter(location.href, key, val);
};

window.setIframeSize = function() {
  if ($(document).width() < 798) {
    $(".iframe-body").css("width", "inherit");
  } else {
    $(".iframe-body").css("width", $(window).outerWidth() - 20);
  }
};

window.setupEditable = function() {
  $this = $(this);
  var defaultValue = $(this).data("editable-default-text");
  var oldText = $this.text().trim();
  $this.addClass("editable-content").prop("contenteditable", true);

  if ($this.text().trim() == "") {
    $(this)
      .css("font-style", "italic")
      .text(defaultValue);
  }

  $this.on("click", function(event) {
    var $this = $(event.target);
    if ($this.text().trim() == defaultValue) {
      $this
        .css("font-style", "normal")
        .text("")
        .focus();
    }
  });

  $this.on("blur", function(event) {
    var $this = $(event.target);
    var editableField = $this.data("editable-field");
    var text = $this.text().trim();
    var url = $this.data("editable-url");
    var data = { _method: "PATCH" };
    data[editableField] = text;

    toggleLineClamp(event);

    if (text == "") {
      $this.css("font-style", "italic").text(defaultValue);
    }

    $.ajax({
      url: url,
      method: "POST",
      data: data,
      error: function(err) {
        if (err.status == 422) {
          Notification.send({
            text: "Field is required."
          });
        } else {
          Notification.send({
            text: "Unable to update. We are looking into this error"
          });
        }

        $this.css("font-style", "normal").text(oldText);
      }
    });
  });
};

$(document).ready(function() {
  $(".mobile-click-flash").on("click", function() {
    var self = $(this);
    self.addClass("gray-flash-box");
    setTimeout(function() {
      self.removeClass("gray-flash-box");
    }, 300);
  });

  $(".show-all-comments").on("click", function() {
    $(this)
      .parent()
      .find(".to-hide")
      .addClass("to-show")
      .removeClass("to-hide");
    $(this)
      .addClass("d-none")
      .removeClass("d-flex");
  });

  $(".crunch-button__toggle-clamp").on("click", function() {
    $(this).toggleClass("active");
    $(this)
      .parent()
      .find(".ui-note")
      .toggleClass("active");
  });

  $("[data-editable]").each(setupEditable);

  tippy(".tippy");

  // apply the border around ui-avatar images with white background
  $('.white-background[data-src*="ui-avatar"]').css(
    "border",
    "2px solid #ececec"
  );

  $(window).resize(setIframeSize);
  setIframeSize();

  let $toHide = $(".single-category-box.hidden");

  $(".js-category-button").on("click", function() {
    $(this).toggleClass("active");
    $($toHide).toggleClass("hidden");
  });

  $(".js-category-search").on("click", function() {
    $(".ui-search-form .ui-search-input input").val(
      $(this)
        .find(".single-category-box__title")
        .text()
    );
    $(".ui-search-submit-btn").click();
  });

  $(".ui-note,.ui-group-description").each(function() {
    $text = $(this)
      .text()
      .trim()
      .replace(/(\r\n|\n|\r)/gm, " ");
    $(this).text($text);
    if ($(window).width() < 480) {
      if ($(this).text().length < 37) {
        $(this)
          .parent()
          .find(".crunch-button__toggle-clamp")
          .addClass("d-none");
      }
    } else {
      if ($(this).text().length < 46) {
        $(this)
          .parent()
          .find(".crunch-button__toggle-clamp")
          .addClass("d-none");
      }
    }
  });

  $(document).on("click", ".js-remove-recomendation", function() {
    $(this)
      .closest(".ui-groups-show-rec-objects")
      .addClass("disabled-overlay");

    $(".ui-remove-container").removeClass("active");

    $(this)
      .closest(".ui-rec-object-box")
      .find(".ui-remove-container")
      .addClass("active");

    $(this)
      .closest(".ui-rec-object-box")
      .addClass("collapsed-box");
    $.ajax({
      url:
        endpoint +
        "/rec_objects/" +
        $(this)
          .closest(".ui-rec-object-box")
          .data("id"),
      method: "POST",
      data: { _method: "DELETE" },
      success: function(data) {
        console.log(data);
      }
    });
  });

  $(document).on("click", ".js-remove-recomendation--section", function() {
    $(this)
      .closest(".ui-groups-show-rec-objects")
      .addClass("disabled-overlay");

    $(".ui-remove-container").removeClass("active");

    let $name = $(this)
      .closest(".ui-section")
      .find(".js-section-input-name")
      .val();
    $(this)
      .closest(".ui-section")
      .find(".js-ui-section-name")
      .text($name);
    let $counter = $(this)
      .closest(".ui-section")
      .find(".js-items-counter")
      .text();
    $(this)
      .closest(".ui-section")
      .find(".js-remove-container-counter")
      .text($counter);

    $(this)
      .closest(".ui-section")
      .find(".ui-remove-container")
      .first()
      .addClass("active");

    $(this)
      .closest(".ui-section")
      .addClass("collapsed-box");
  });

  $(document).on("click", ".js-rec-object-box-add-button", function() {
    $(this)
      .parent()
      .toggleClass("active");
  });
  $(document).on(
    "click",
    ".ui-rec-object-box--form button:not([type='submit']):not('.js-ui-add-recomendation-box-action-cancel'),.ui-section--new button",
    function(e) {
      e.preventDefault();
    }
  );

  $(document).on(
    "click",
    ".js-ui-rec-object-box-add-item-button--recommendation",
    function() {
      $(this)
        .closest(".ui-rec-object-box-add")
        .toggleClass("active");
      let $afterElement = $(this).closest(".ui-rec-object-box");
      let template = `<form class="ui-rec-object-box editable-object ui-rec-object-box--form ui-rec-object-box--comment-hide animate">
        <div class="someone-comment-box">
            <div class="ui-comments position-relative">
              <div class="ui-comment ">
                <div class="ui-comment-body align-items-start">
                  <a href="/profiles/1" class="d-flex align-items-center" style="flex-basis: 25px;">
                    <img class="ui-avatar lazyloaded" src="${misc.user_avatar}">
                  </a>
                  <div class="ui-name-and-comment">
                    <div>
                      <a href="/profiles/${misc.user_id}" class="font-size-15-17 font-weight-medium text-black ui-profile-name editable">${misc.user_name}</a>
                    </div>
                    <div class="position-relative">
                    <div class="font-size-13-15 ui-note ui-note-1695 editable-content" data-editable data-editable-field="rec[user_note]" data-editable-default-text="Add optional note" contenteditable="true"></div>
                      <button class="crunch-button crunch-button__toggle-clamp d-none">
                        <div class="icon">
                          <svg width="9" height="5" class="adjustable-element" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75924 0.349209C8.39982 -0.0701172 7.76852 -0.118679 7.34919 0.240744L4.49998 2.68292L1.65077 0.240744C1.23145 -0.118679 0.600147 -0.0701173 0.240725 0.349209C-0.118698 0.768535 -0.0701356 1.39983 0.34919 1.75926L3.84919 4.75926C4.22368 5.08025 4.77628 5.08025 5.15077 4.75926L8.65077 1.75926C9.0701 1.39983 9.11866 0.768535 8.75924 0.349209Z" fill="#5E518E"></path>
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-comment-box position-relative">
              <div class="ui-processing-box position-absolute d-flex justify-content-center align-items-center flex-column">
                <div class="ui-processing-box-label">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.435407 8.89382L2.23669 9.0064L0.660567 15.1983C0.491697 15.8175 1.22347 16.2678 1.67379 15.8175L8.71003 7.59915C8.99148 7.26141 8.76632 6.64222 8.25971 6.64222H6.0644L9.04777 0.90064C9.27293 0.50661 8.99148 0 8.54116 0H3.13733C2.85588 0 2.63072 0.16887 2.57443 0.39403L0.0413768 8.16205C-0.0712032 8.4435 0.0413768 8.89382 0.435407 8.89382Z" fill="#514B67"/>
                  </svg>
                  <span class="d-block processing-label font-size-13 font-weight-medium opacity-50">Still Processing</span>
                </div>
                <span class="d-block processing-text font-size-13 opacity-50">Automatically pulling in info (e.g. photos, etc)</span>
              </div>
              <div class="ui-main-box position-relative z-index-2">
                <div class="ui-top d-flex align-items-center justify-content-center">
                 <span class="font-size-20 text-dark-black d-block text-center font-weight-medium">Recommendation info</span>
                </div>
                <div class="ui-center ui-center--form">
                    <div class="ui-input-wrapper d-flex w-100 c-mt-25-p">
                      <input class="ui-input font-size-15-17" name="name" placeholder="Type name or paste url here" required="required"/>
                      <span class="ui-input-label d-block">Name</span>
                    </div>
                    <div class="ui-input-wrapper d-flex w-100 c-mt-25-p">
                      <input class="ui-input font-size-15-17" name="tag" placeholder="E.g. dentist, book, japanese restaurant"/>
                      <span class="ui-input-label d-block">Tag* <span class="font-size-13">(Optional)</span></span>
                    </div>
                    <div class="ui-input-wrapper d-flex w-100 c-mt-25-p">
                      <input class="ui-input font-size-15-17" name="note" placeholder="E.g. hands down the best in the area"/>
                      <span class="ui-input-label d-block">Note* <span class="font-size-13">(Optional)</span></span>
                    </div>
                  </div>
              </div>

              <div class="ui-add-recomendation-box-actions">
                <div class="d-flex justify-content-center flex-wrap position-relative z-index-2">
                  <button type="submit" class="ui-add-recomendation-box-action ui-add-recomendation-box-action--add-recommendation font-size-14 font-weight-medium js-ui-add-recomendation-box-action-add-recommendation">Add recommendation</button>
                  <button class="ui-add-recomendation-box-action ui-add-recomendation-box-action--cancel js-ui-add-recomendation-box-action-cancel font-size-15 font-weight-medium text-dark-black">Cancel</button>
                </div>
              </div>
            </div>
        </div>
      </form>`;

      if ($afterElement.length == 0) {
        let $beforeElement = $(this)
          .closest(".ui-section")
          .find(".ui-rec-object-box")
          .first();
        if ($beforeElement.length == 0) {
          $beforeElement = $(this)
            .closest(".ui-section")
            .find(".ui-rec-object-group");
          $(template).appendTo($($beforeElement));
        } else {
          $(template).insertBefore($($beforeElement));
        }
      } else {
        $(template).insertAfter($($afterElement));
      }
      $("[data-editable]").each(setupEditable);
    }
  );

  $(document).on(
    "click",
    ".js-ui-rec-object-box-add-item-button--section",
    function() {
      $(this)
        .closest(".ui-rec-object-box-add")
        .toggleClass("active");
      let $afterElement = $(this).closest(".ui-section");
      let rand = Math.floor(Math.random() * 101);
      let id = "new-" + rand;
      let template = `
      <form class="ui-section ui-section--new" id="${id}">
        <div class="ui-section-header c-mt-md-3 position-relative">
          <div class="position-relative">
            <div class="c-px-md-7 c-px-lg-8">
              <input class="text-center font-size-25-30 font-weight-medium  line-height-1-5 editable-content w-100 ui-section-input js-section-input-name" placeholder='Type a section name here' value="New section">
            </div>
            <!--<input class="text-center font-size-15 font-weight-base editable-content w-100 ui-section-input ui-section-input--description js-section-input-description" placeholder='Be creative with the name'>-->
            <div class="position-absolute ui-rec-object-box-actions">
              <button class="ui-rec-object-box-action ui-rec-object-box-action--move">
                <div class="ui-rec-object-box-action-inner">
                  <div class="ui-rec-object-box-action-icon">
                    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15 1.5L1 1.5" stroke="#A893F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M15 5.5L1 5.5" stroke="#A893F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M15 9.5H1" stroke="#A893F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
              </button>
              <button class="ui-rec-object-box-action ui-rec-object-box-action--remove js-remove-recomendation--section">
                <div class="ui-rec-object-box-action-inner">
                  <div class="ui-rec-object-box-action-icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C10.5523 0 11 0.447715 11 1C11 1.55228 11.4477 2 12 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H4C4.55228 2 5 1.55228 5 1C5 0.447715 5.44772 0 6 0H10ZM3.21365 15.4616C2.84389 15.1173 2.61761 14.6462 2.58002 14.1423L2.02721 6.40427C1.98586 5.82539 2.44433 5.33301 3.02467 5.33301H12.9754C13.5557 5.33301 14.0142 5.82539 13.9728 6.40426L13.42 14.1423C13.3824 14.6462 13.1561 15.1173 12.7864 15.4616C12.4166 15.8059 11.9306 15.9981 11.4254 15.9997H4.57468C4.06944 15.9981 3.58341 15.8059 3.21365 15.4616Z"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
            <div class="position-absolute w-100 h-auto ui-remove-container text-center d-flex flex-column justify-content-between align-items-center"> 
              <div class="ui-top"> 
                <span class="d-block text-center ui-remove-container-label font-size-15-17 font-weight-medium">Delete section</span>
              </div>
              <div class="ui-center">
                <h2 class="text-center font-size-25-30 font-weight-medium  line-height-1-5 w-100 js-ui-section-name"></h2>
                <div class="d-flex justify-content-center c-mt-5 c-pt-2 c-pt-md-1 c-mt-md-3">
                  <div class="d-flex align-items-center text-center">
                    <div class="emoji-wrapper emoji-wrapper--small">
                      <img class="adjustable-element" src="/emojis/purple_star.png">
                    </div>
                    <span class="font-size-13 font-weight-medium c-px-10-p d-block">Items</span>
                    <span class="font-size-13 opacity-50 js-remove-container-counter">2</span>
                  </div>
                </div>
              </div>
              <div class="ui-bottom w-100">
                <div class="ui-remove-container-buttons d-flex justify-content-center flex-column">
                  <button class="ui-remove-container-confirm js-ui-remove-container-confirm-section w-100">
                    Delete section and items within it
                  </button>
                  <button class="ui-remove-container-cancel w-100 font-weight-medium">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center c-mt-5 c-pt-2 c-pt-md-0 c-mt-md-3">
              <div class="d-flex align-items-center text-center">
                <div class="emoji-wrapper emoji-wrapper--small">
                  <img class="adjustable-element" src="/emojis/purple_star.png">
                </div>
                <span class="font-size-13 font-weight-medium c-px-10-p d-block">Items</span>
                <span class="font-size-13 opacity-50 js-items-counter">0</span>
              </div>
            </div>
            <div class="ui-rec-object-box-add">
              <button  type='button' class="ui-rec-object-box-add-button js-rec-object-box-add-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M10.5 6H1.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M6 1.5V10.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                </button>
                <div class="ui-rec-object-box-add-container">
                  <button type='button'  class="ui-rec-object-box-add-item-button ui-rec-object-box-add-item-button--recommendation js-ui-rec-object-box-add-item-button--recommendation">
                      <div class="d-flex flex-column">
                        <div class="ui-rec-object-box-add-item-button-icon">
                          <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.7434 11.8862L22.8066 1.38983C22.3291 0.529409 21.4508 0 20.5008 0C19.5508 0 18.6725 0.529409 18.1951 1.38983L13.2582 11.8889L2.1983 13.5717C1.23212 13.7168 0.428625 14.4245 0.126174 15.3967C-0.176277 16.3689 0.0748742 17.4366 0.773863 18.1503L8.77481 26.3225L6.88666 37.8629C6.72198 38.8698 7.11718 39.8872 7.90613 40.4876C8.69507 41.0879 9.74094 41.167 10.604 40.6917L20.5008 35.2408L30.3925 40.6863C31.2556 41.1617 32.3014 41.0826 33.0904 40.4822C33.8793 39.8819 34.2745 38.8644 34.1098 37.8576L32.2217 26.3171L40.2278 18.1503C40.925 17.4372 41.1757 16.3714 40.8745 15.4006C40.5734 14.4298 39.7726 13.722 38.8084 13.5744L27.7434 11.8862Z" fill="url(#paint0_angular)"></path>
                            <defs>
                            <radialGradient id="paint0_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5 20.5) scale(20.5)">
                            <stop offset="0.75" stop-color="#E0D8FF"></stop>
                            <stop offset="0.75" stop-color="#8E71FF"></stop>
                            </radialGradient>
                            </defs>
                          </svg>
                        </div>
                        <span class="ui-rec-object-box-add-button-name">Add Recomendation</span>
                      </div>  
                  </button>
                  <button type='button'  class="ui-rec-object-box-add-item-button ui-rec-object-box-add-item-button--section js-ui-rec-object-box-add-item-button--section">
                      <div class="d-flex flex-column">
                        <div class="ui-rec-object-box-add-item-button-icon">
                          <svg width="40" height="46" viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1 26H16C16.552 26 17 25.552 17 25V1C17 0.448 16.552 0 16 0H1C0.448 0 0 0.448 0 1V25C0 25.552 0.448 26 1 26ZM1 46H16C16.552 46 17 45.552 17 45V33C17 32.448 16.552 32 16 32H1C0.448 32 0 32.448 0 33V45C0 45.552 0.448 46 1 46ZM39 14H24C23.448 14 23 13.552 23 13V1C23 0.448 23.448 0 24 0H39C39.552 0 40 0.448 40 1V13C40 13.552 39.552 14 39 14ZM24 46H39C39.552 46 40 45.552 40 45V21C40 20.448 39.552 20 39 20H24C23.448 20 23 20.448 23 21V45C23 45.552 23.448 46 24 46Z" fill="url(#paint0_linear)"></path>
                            <defs>
                            <linearGradient id="paint0_linear" x1="25.5" y1="32.5" x2="11.5" y2="8.5" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FEAF63"></stop>
                            <stop offset="1" stop-color="#FFCB99"></stop>
                            </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <span class="ui-rec-object-box-add-button-name">New Section</span>
                      </div>  
                  </button>
                </div>
            </div>
          </div>
        </div>
        <div class="ui-rec-object-group"></div>
      </form>`;

      if ($afterElement.length == 0) {
        let $beforeElement = $(this).closest(".ui-rec-object-box");
        $(template).insertBefore($($beforeElement));
      } else {
        $(template).insertAfter($($afterElement));
      }
      $("html, body").animate(
        {
          scrollTop: $("#" + id).offset().top
        },
        1200
      );
      $("[data-editable]").each(setupEditable);
    }
  );

  $(document).on("submit", ".ui-section--new", function(e) {
    e.preventDefault();
    return false;
  });

  $(document).on("keyup", ".js-section-input-name", function(e) {
    $this = $(this);
    if (e.keyCode == 13) {
      $.ajax({
        url: endpoint + "/sections",
        method: "POST",
        data: {
          section: {
            name: $(this).val()
          }
        },
        success: function(data) {
          $($this)
            .closest(".ui-section--new")
            .replaceWith(data);
        }
      });
    }
  });

  $(document).on(
    "click",
    ".ui-remove-container-confirm,.ui-remove-container-cancel",
    function() {
      $(this)
        .closest(".ui-groups-show-rec-objects")
        .removeClass("disabled-overlay");
      $(this)
        .closest(".ui-remove-container")
        .removeClass("active");
      $(this)
        .closest(".ui-rec-object-box")
        .removeClass("collapsed-box");
      $(this)
        .closest(".ui-section")
        .removeClass("collapsed-box");
    }
  );

  $(document).on(
    "click",
    ".ui-rec-object-box--form .js-ui-add-recomendation-box-action-cancel",
    function(e) {
      e.preventDefault();
      $(this)
        .closest(".ui-rec-object-box--form")
        .remove();
    }
  );

  $(document).on(
    "click",
    ".js-ui-remove-container-confirm-section",
    function() {
      $.ajax({
        url:
          endpoint +
          "/sections/" +
          $(this)
            .closest(".ui-section")
            .data("id"),
        method: "POST",
        data: { _method: "DELETE" },
        success: function(data) {
          console.log(data);
        }
      });
      $(this)
        .closest(".ui-section")
        .remove();
    }
  );

  $(document).on("click", ".js-ui-remove-container-confirm", function() {
    $(this)
      .closest(".ui-rec-object-box")
      .remove();
  });

  $(document).on("submit", ".ui-rec-object-box--form", function(e) {
    e.preventDefault();
    $(this)[0].checkValidity();
    console.log("xxxx");
    $this = $(this);
    $this.toggleClass("animate");
    $this.addClass("processing");
    var data = JSON.stringify($(this).serializeArray());
    console.log(data);
    $.ajax({
      url: endpoint + "/recommendations",
      method: "POST",
      data: {
        rec: {
          user_note: "this resturant is awesome!",
          rec_object_attributes: {
            name1: "Resturant Name"
          }
        }
      },
      success: function(data) {
        $($this).replaceWith(data);
      }
    });
  });
});

// $(window).on("scroll",function(){
//   if(window.pageYOffset > 150) {
//     $(".show-after-scroll").addClass("active").removeClass("out");
//   }else {
//     $(".show-after-scroll").removeClass("active").addClass("out");
//   }
// })
// import TweenLite from "greensock/TweenLite";
// import TimelineMax from "greensock/TimelineMax";
// import Power2 from "gsap";
// import gsap from "gsap";
// import "greensock/TweenMax";
// import {MotionPathPlugin} from "gsap/all";

//     let target = "#test";
//     function removeElement() {
//       // target.parentNode.removeChild(target);
//     }

//     function animation1(target) {
//       let tl = new TimelineMax({onComplete:removeElement()});
//       tl.to(target, 2,
//         {bezier:{type:"soft",
//         values:[
//         {x:1 + Math.random() *5, y:-60},
//         {x:50 - Math.random() *20, y:-120},
//         {x:-100 + Math.random() *100, y:-240, opacity:0}]}
//         , ease:Power1.easeInOut})
//         .to(target, .5, {css:{opacity:.75}, ease: Sine.easeOut}, "-=1")
//         .to(target, .5, {ease: Sine.easeOut}, "-=1")
//         .to(target, .07, {css:{opacity:1,scaleX:"2",scaleY:"2"}})
//         .to(target, .07, {css:{scaleX:"1" ,scaleY:"1"}})
//         .to(target, .07, {css:{display:"none"}});
//     }

//     function animation2(target) {
//       let tl = new TimelineMax({onComplete:removeElement()});
//       tl.to(target, 2,
//         {bezier:{type:"soft",
//         values:[
//         {x:-1 + Math.random() *90, y:-60},
//         {x:-25 - Math.random() *80, y:-120},
//         {x:-100 + Math.random() *100, y:-240, opacity:0}]}
//         , ease:Power1.easeInOut})
//         .to(target, .5, {css:{opacity:.75}, ease: Sine.easeOut}, "-=1")
//         .to(target, .5, {ease: Sine.easeOut}, "-=1")
//         .to(target, .07, {css:{opacity:1,scaleX:"2",scaleY:"2"}})
//         .to(target, .07, {css:{scaleX:"1" ,scaleY:"1"}})
//         .to(target, .07, {css:{display:"none"}});
//     }

//     function animation3(target) {
//       let tl = new TimelineMax({onComplete:removeElement()});
//       tl.to(target, 2,
//         {bezier:{type:"soft",
//         values:[
//         {x:-5 + Math.random() *25, y:-60},
//         {x:-50 - Math.random() *35, y:-120},
//         {x:-100 + Math.random() *100, y:-240, opacity:0}]}
//         , ease:Power1.easeInOut})
//         .to(target, .5, {css:{opacity:.75}, ease: Sine.easeOut}, "-=1")
//         .to(target, .5, {ease: Sine.easeOut}, "-=1")
//         .to(target, .07, {css:{opacity:1,scaleX:"2",scaleY:"2"}})
//         .to(target, .07, {css:{scaleX:"1" ,scaleY:"1"}})
//         .to(target, .07, {css:{display:"none"}});
//     }

//     function animation4(target) {
//       let tl = new TimelineMax({onComplete:removeElement()});
//       tl.to(target, 2,
//         {bezier:{type:"soft",
//         values:[
//         {x:-3 + Math.random() *10, y:-60},
//         {x:40 - Math.random() *15, y:-120},
//         {x:-100 + Math.random() *100, y:-240, opacity:0}]}
//         , ease:Power1.easeInOut})
//         .to(target, .5, {css:{opacity:.75}, ease: Sine.easeOut}, "-=1")
//         .to(target, .5, {ease: Sine.easeOut}, "-=1")
//         .to(target, .07, {css:{opacity:1,scaleX:"2",scaleY:"2"}})
//         .to(target, .07, {css:{scaleX:"1" ,scaleY:"1"}})
//         .to(target, .07, {css:{display:"none"}});
//     }

//     function animation5(target) {
//       let tl = new TimelineMax({onComplete:removeElement()});
//       tl.to(target, 2,
//         {bezier:{type:"soft",
//         values:[
//         {x:-3 + Math.random() *10, y:-60},
//         {x:-60 - Math.random() *15, y:-120},
//         {x:-100 + Math.random() *100, y:-240, opacity:0}]}
//         , ease:Power1.easeInOut})
//         .to(target, .5, {css:{opacity:.75}, ease: Sine.easeOut}, "-=1")
//         .to(target, .5, {ease: Sine.easeOut}, "-=1")
//         .to(target, .07, {css:{opacity:1,scaleX:"2",scaleY:"2"}})
//         .to(target, .07, {css:{scaleX:"1" ,scaleY:"1"}})
//         .to(target, .07, {css:{display:"none"}});
//     }

// (function($){
//     $(function() {
//       $(".crunch-button__clappings").on("click",function(){
//         let clap = document.createElement('div');
//         clap.className = 'clap';

//         $(this).append(clap);
//         var rand = Math.floor(Math.random() * 4) + 0;

//         switch (rand) {
//           case 0:
//             animation1(clap);
//             break;
//           case 1:
//             animation2(clap);
//             break;
//           case 2:
//              animation3(clap);
//             break;
//           case 3:
//             animation4(clap)
//             break;
//           case 4:
//             animation5(clap)
//             break;
//         }

//         $(this).addClass("active");
//         let $this = $(this);
//         setTimeout(function () {
//           $this.removeClass("active");
//         }, 150);
//       });
//     });
//   })(jQuery);

$.fn.isInViewport = function() {
  let elementTop = $(this).offset().top;
  let elementBottom = elementTop + $(this).outerHeight();

  let viewportTop = $(window).scrollTop();
  let viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};
let $mobileSectionName = $(".js-current-section-name");
let $mobileCounter = $(".js-current-section-counter");

$(window).on("scroll", function() {
  $(".ui-section").each(function() {
    if ($(this).isInViewport()) {
      $(".show-after-scroll").addClass("active");
      $total = $(this).find(".ui-rec-object-box").length;
      $name = $(this)
        .find(".ui-section-header h2")
        .text();
      $(this)
        .find(".ui-rec-object-box")
        .each(function() {
          if ($(this).isInViewport()) {
            $(".js-current-section-counter").text(
              $(this).index() + 1 + "" + " of " + $total
            );
          }
        });
      $(".js-current-section-name").text($name);

      if (
        !$(this)
          .parent()
          .isInViewport()
      ) {
        $(".show-after-scroll").removeClass("active");
      }
    } else {
    }

    if (window.pageYOffset < 150) {
      $(".show-after-scroll").removeClass("active");
    }
  });
});
function owlInitialize(item) {
  if ($(window).width() < 1080) {
    $(item).addClass("owl-carousel");
    $(item).owlCarousel({
      loop: false,
      nav: false,
      dots: true,
      margin: 15,
      dotsContainer: "#custom-dots",
      responsive: {
        0: {
          items: 2
        },
        480: {
          items: 3
        },
        767: {
          items: 4
        },

        991: {
          items: 5
        }
      }
    });
  } else {
    $(item).owlCarousel("destroy");
    $(item).removeClass("owl-carousel");
  }
}
$(document).ready(function() {
  owlInitialize($(".js-category-carousel"));
});
$(window).on("resize", function() {
  owlInitialize($(".js-category-carousel"));
});

require("utils/notification");
